import { LOCALES } from "../locales";

const locale_english = {
  [LOCALES.ENGLISH]: {
    'hello': "Hi, {name}!",
    'exit': "Exit",
    "app.login.welcome": "Welcome",
    "app.hero.pretitle": "Sports science by Franco Davín's Team",
    "app.hero.title": "Data driven performance",
    "app.hero.logoSubtitle": "Powered by",
    "app.login.signin": "Sign in",
    "app.login.donthaveaccount": "Still don't have an account?",
    "app.login.loginaccount": "Login to your account",
    "app.login.signup": "Sign up here",
    "app.register.welcome": "Let's start",
    "app.register.registeraccount": "Create your account",
    "app.register.signup": "Sign up",
    "app.register.haveanaccount": "Already have an account?",
    "app.register.signin": "Sign in here",
    'username': "Username",
    'password': "Password",
    'firstname': "First name",
    'lastname': "Last name",
    'email': "Email",
    'dni': "DNI",
    'repeatPassword': "Repeat password",
    "click on bar to see details": "Click on bar to see details",
    "sort by": "sort by",
    'points': "points",
    'effectiveness': "effectiveness",
    "first serve rally length": "First Serve Rally Length",
    "second serve rally length": "Second Serve Rally Length",
    "first serve return rally length": "First Serve Return Rally Length",
    "second serve return rally length": "Second Serve Return Rally Length",
    "rally on first serve and return": "Rally on First Serve and Return",
    "rally on second serve and return": "Rally on Second Serve and Return",
    "If you keep these values ​​the probability of winning matches is very low":
      "If you keep these values ​​the probability of winning matches is very low",
    "If you keep these values ​​the probability of winning matches is low":
      "If you keep these values ​​the probability of winning matches is low",
    "If you keep these values ​​you have some chances of winning matches":
      "If you keep these values ​​you have some chances of winning matches",
    "If you keep these values ​​you have high chances of winning matches":
      "If you keep these values ​​you have high chances of winning matches",
    'serve': "Serve",
    'sem': "S.E.M.",
    'flow': "Flow",
    'rs': "RS",
    "4m": "4M",
    'overview': "Overview",
    'notifications': "Notifications",
    'matches': "Matches",
    "upload match": "Upload match",
    'dashboard': "Dashboard",
    'mole': "Mole",
    "account settings": "Account settings",
    "player profile": "Player profile",
    "General Serve Stats": "General Serve Stats",
    "2nd Serve": "2nd Serve",
    "1st Serve": "1st Serve",
    "Deuce and ad points won on serve and return":
      "Deuce and ad points won on serve and return",
    "Deuce and ad points won on 2nd serve":
      "Deuce and ad points won on 2nd serve",
    "Service Effectiveness Margin in the Match": "Service Effectiveness Margin in the Match",
    "Return Effectiveness Margin in the Match": "Return Effectiveness Margin in the Match",
    'setdesireddescription':
      "This margin will define how much you'd like to improve your player's game. You'll be able to set your goal anytime you want.",
    "Leadership margin": "Leadership margin",
    "Match General Stats": "Match General Stats",
    "Match quality": "Match quality",
    "Set desired margin": "Set desired margin",
    "Match performance": "Match performance",
    'rally': "Rally",
    'minimatches': "Minimatches",
    'M4': "M4",
    "unforced errors": "Unforced Errors",
    "P1": "P1",
    "Total chains": "Total chains",
    "Total points in a row": "Total points in a row",
    "Distribution of unforced errors in a row":
      "Distribution of unforced errors in a row",
    "unforced errors in a row (UER)": "Unforced Errors in a Row (UER)",
    "P1 (Priority 1)": "Priority 1 (Unforced Errors)",
    "Percentage of Determinant Unforced Errors (DUE) over Total Unforced Errors":
      "Percentage of Determinant Unforced Errors (DUE) over Total Unforced Errors",
    "Very good": "Very good",
    'Good': "Good",
    'Excellent': "Excellent",
    'Regular': "Regular",
    'Bad': "Bad",
    "Needs improvement": "Needs improvement",
    "Forehand and Backhand Key Shots": "Forehand and Backhand Key Shots",
    "double click to expand": "Double click to expand",
    'total': "Total",
    'search': "Search",
    'records': "Records",
    'show': "Show",
    'of': "of",
    'page': "page",
    'actions': "Actions",
    'umpire': "Umpire",
    'ball': "Ball",
    'surface': "Surface",
    'tournament': "Tournament",
    'won': "Won",
    'endTime': "EndTime",
    'startTime': "StartTime",
    'date': "Date",
    'opponent': "Opponent",
    'delete': "Delete",
    'edit': "Edit",
    'login': "Login",
    "about gsm": "About GSM",
    'enter': "Enter",
    'country': "Country",
    'phone': "Phone",
    'playername': "Player name",
    'datebirth': "Birthday",
    "change password": "Change password",
    'remove': "Remove",
    'change': "Change",
    'save': "Save",
    'cancel': "Cancel",
    'help': "Help",
    'billing': "Billing",
    "personal settings": "Personal settings",
    'apply': "Apply",
    "repeat new password": "Repeat new password",
    "new password": "New password",
    "current password": "Current password",
    'photo': "Photo",
    'upload': "Upload",
    'LOSE': "LOSE",
    "Go to match": "Go to match",
    "Last Match": "Last Match",
    'Weight': "Weight",
    'Height': "Height",
    'Born': "Born",
    'From': "From",
    "Historical Forehand and Backhand Key Shots":
      "Historical Forehand and Backhand Key Shots",
    "Match Quality": "Match Quality",
    "This graphics belong to your player's last 10 matches.":
      "This graphics belong to your player's last 10 matches.",
    "Latest stats summary": "Summary of the latest 10 matches stats",
    'Surfaces': "Surfaces",
    'reset': "Reset",
    'return': "Return",
    'performance': "Performance",
    "subtitle determinant unforced errors": "As the name suggests, this were the unforced errors during the average of your last ten matches, that can change your game and results inmediately. This are key errors to find a way to reduce them as much as you can.",
    "No data": "No data",
    'Rounds': "Rounds",
    'Tournament': "Tournament",
    'Date': "Date",
    'Winner': "Winner",
    'Empty': "Empty",
    "Add category": "Add category",
    "Add federation": "Add federation",
    "Add favourite surface": "Add favourite surface",
    "Add backhand": "Add backhand",
    "Add hand": "Add hand",
    'WON': "WON",
    "Complete your player's profile": "Complete your player's profile",
    "No have ranking information yet.": "No have ranking information yet.",
    "Last match": "Last match",
    'here': "here",
    'Awards': "Awards",
    'Strings': "Strings",
    'Model': "Model",
    'Brand': "Brand",
    'Racket': "Racket",
    'Rankings': "Rankings",
    'Category': "Category",
    'Federation': "Federation",
    "Favourite surface": "Favourite surface",
    'Backhand': "Backhand",
    'Hand': "Hand",
    'Profile': "Profile",
    'profile': "Profile",
    'radar': "Radar",
    "End time": "End time",
    "Start time": "Start time",
    "Match date": "Match date",
    'Umpire': "Umpire",
    'Ball': "Ball",
    'Surface': "Surface",
    'Grass': "Grass",
    "Edit match": "Edit match",
    "Effectiveness in the range of rallies that is repeated the most in each match (Mode)":
      "Effectiveness in the range of rallies that is repeated the most in each match (Mode)",
    "% RM": "% RM",
    "Effectiveness in the average range of rallies":
      "Effectiveness in the average range of rallies",
    "% RA": "% RA",
    "Percentage of won points": "Percentage of won points",
    "% WP": "% WP",
    "Percentage of won matches": "Percentage of won matches",
    "% WM": "% WM",
    "Percentage of won points in return games":
      "Percentage of won points in return games",
    "% RWP": "% RWP",
    "Percentage of won points in service games":
      "Percentage of won points in service games",
    "% SWP": "% SWP",
    'References': "References",
    'Skillset': "Skillset",
    "passwords must match": "Passwords must match.",
    "player name": "Player name",
    'export': "Export",
    "confirm changes": "Confirm changes?",
    "changes saved": "Changes saved",
    "What is GSM?": "What is GSM?",
    "A tool developed by Franco Davin to enhance the performance of players of any level.":
      "A tool developed by Franco Davin to enhance the performance of players of any level.",
    "Game Set Math is a science project focused on the data analysis and new statistics applied to tennis as never seen before.":
      "Game Set Math is a science project focused on the data analysis and new statistics applied to tennis as never seen before.",
    "How many times have you been told subjective features about a player, without having real data that backs up those comments? How often are opinions formed over a player&apos;s development with no certainty of the process that is going to be done?":
      "How many times have you been told subjective features about a player, without having real data that backs up those comments? How often are opinions formed over a player&apos;s development with no certainty of the process that is going to be done?",
    "This tool will help in focusing the process for the development of a player with exact data about the actual and the projected status, being efficient with the time and trainings selected for each player.":
      "This tool will help in focusing the process for the development of a player with exact data about the actual and the projected status, being efficient with the time and trainings selected for each player.",
    "It has been used over 20 years privately and now is going to be shared with anybody who is interested in using the technology of the 21st century.":
      "It has been used over 20 years privately and now is going to be shared with anybody who is interested in using the technology of the 21st century.",
    "The following features will be available:":
      "The following features will be available:",
    'Rallies': "Rallies",
    "Average rallies": "Average rallies",
    "Organized rallies per situation (serving, returning), per set and per match":
      "Organized rallies per situation (serving, returning), per set and per match",
    "Identification of winner points through number of rallies":
      "Identification of winner points through number of rallies",
    "% Of points won by first serve": "% Of points won by first serve",
    "% Of points won by second serve": "% Of points won by second serve",
    "Player performance rating": "Player performance rating",
    "Match performance rating": "Match performance rating",
    "Winning/losing points streaks": "Winning/losing points streaks",
    "Match momentum": "Match momentum",
    "What can you get?": "What can you get?",
    "MATCH PERFORMANCE": "MATCH PERFORMANCE",
    "It is the performance level of each of the last ten matches and the match performance average.":
      "It is the performance level of each of the last ten matches and the match performance average.",
    "The desired margin of performance is established":
      "The desired margin of performance is established",
    "The leadership margin shows the percentage of the points of the last ten games that depended on the player":
      "The leadership margin shows the percentage of the points of the last ten games that depended on the player",
    "Match quality takes into account the level of performance of the two players in the last ten matches":
      "Match quality takes into account the level of performance of the two players in the last ten matches",
    "SERVICE EFFECTIVE MARGIN (S.E.M.)": "SERVICE EFFECTIVE MARGIN (S.E.M.)",
    "The service effective margin refers to the effectiveness in game services, including the performance of the first and second serve. On this basis, the probability that the service will be broken is calculated.":
      "The service effective margin refers to the effectiveness in game services, including the performance of the first and second serve. On this basis, the probability that the service will be broken is calculated.",
    "How can we help you?": "How can we help you?",
    "With a technological tool with more than 20 years of development and proven results with pro and amateur players, for you to use in your organization.":
      "With a technological tool with more than 20 years of development and proven results with pro and amateur players, for you to use in your organization.",
    "Access to the GSM reporting": "Access to the GSM reporting",
    "Matches data input": "Matches data input",
    "Weekly/Monthly reports. Recommendations and special analysis to be supplied":
      "Weekly/Monthly reports. Recommendations and special analysis to be supplied",
    "Check what best suits for you at:": "Check what best suits for you at:",
    "In addition, the return effective margin refers to the effectiveness in return services, including the performance of the first and second serve return. On this, basis, the probability that the opponent serve will be broken is calculated.":
      "In addition, the return effective margin refers to the effectiveness in return services, including the performance of the first and second serve return. On this, basis, the probability that the opponent serve will be broken is calculated.",
    "This report is a summary that contains information on the main parameters of the player in the last 10 matches released. Full information is available on the GSM WEB APP:":
      "This report is a summary that contains information on the main parameters of the player in the last 10 matches released. Full information is available on the GSM WEB APP:",
    "How to read the report?": "How to read the report?",
    "Observe if it is an initial, intermediate or final report. Regardless of the number, the initial report may correspond to the start of a full season or the start of any other mini-cycle established during the season. The intermediate reports are those made periodically during any cycle excluding the initial or final report. The final report corresponds to the end of the season or the end of a mini-cycle.":
      "Observe if it is an initial, intermediate or final report. Regardless of the number, the initial report may correspond to the start of a full season or the start of any other mini-cycle established during the season. The intermediate reports are those made periodically during any cycle excluding the initial or final report. The final report corresponds to the end of the season or the end of a mini-cycle.",
    "The initial report shows the player parameters at the start of a cycle. By determining the level of the player at the time of starting the job, it is neither good nor bad, but only marks a starting point. The establishment of short, medium and long-term objectives will determine the intermediate and final points of the path to follow.":
      "The initial report shows the player parameters at the start of a cycle. By determining the level of the player at the time of starting the job, it is neither good nor bad, but only marks a starting point. The establishment of short, medium and long-term objectives will determine the intermediate and final points of the path to follow.",
    "The intermediate report show the parameters of the player in the different stages of work within a cycle. They allow comparison with the initial parameters and with other intermediate reports. It is important to note that the comparison between average reports will not necessarily express constant improvements so that at the end of the cycle there is progress in the player&apos;s level. This means that there may be setbacks and advances that are logical in a learning process. Error precedes progress, and learning new skills may first cause a drop in level of play.":
      "The intermediate report show the parameters of the player in the different stages of work within a cycle. They allow comparison with the initial parameters and with other intermediate reports. It is important to note that the comparison between average reports will not necessarily express constant improvements so that at the end of the cycle there is progress in the player&apos;s level. This means that there may be setbacks and advances that are logical in a learning process. Error precedes progress, and learning new skills may first cause a drop in level of play.",
    "The final report shows the parameters of the player at the end of a cycle, it can be the final report of a season or a mini-cycle within it. This report must be compared with the initial report, to observe the progress, and in the items in which no improvements are seen, to be able to analyze the causes.":
      "The final report shows the parameters of the player at the end of a cycle, it can be the final report of a season or a mini-cycle within it. This report must be compared with the initial report, to observe the progress, and in the items in which no improvements are seen, to be able to analyze the causes.",
    "Report type": "Report type",
    "Game level": "Game level",
    "The desired margin of performance is established.":
      "The desired margin of performance is established.",
    "The leadership margin shows the percentage of the points of the last ten games that depended on the player.":
      "The leadership margin shows the percentage of the points of the last ten games that depended on the player.",
    "Match quality takes into account the level of performance of the two players in the last ten matches.":
      "Match quality takes into account the level of performance of the two players in the last ten matches.",
    'simulation': 'Simulation',
    'report': 'Report',
    'determinant unforced errors': 'Determinant Unforced Errors (DUE)',
    'simulate': 'Simulate',
    'unforcedErrorsSecondServeReturn': '2nd Serve Return',
    'unforcedErrorsThirdShotFirstServe': '1st Serve + 1',
    'unforcedErrorsThirdShotSecondServe': '2nd Serve + 1',
    'unforcedErrorsFourthShotFirstServe': '1st Serve Return + 1',
    'unforcedErrorsFourthShotSecondServe': '2nd Serve Return + 1',
    'doubleFaults': 'Double Faults',
    'totalPointsInSequences': 'Total Points In Sequences',
    'loading': 'Loading',
    'summary of player skills': 'Summary of player skills',
    'comments': 'Comments',
    'Return Effective Margin (R.E.M.)': 'Return Effective Margin (R.E.M.)',
    'Service Effective Margin (S.E.M.)': 'Service Effective Margin (S.E.M.)',
    'insert comments here...': 'Insert comments here...',
    'generating the report': 'Generating the report...',
    'report type': 'Report type',
    'content': 'Content',
    'Introduction': 'Introduction',
    'Print': 'Print',
    'You must do the simulation before saving': 'You must do the simulation before saving',
    'You must wait until the simulation is over.': 'You must wait until the simulation is over.',
    "The graph shows the player's level of play from each of the last 10 matches. On this basis, the performance average is calculated.": "The graph shows the player's level of play from each of the last 10 matches. On this basis, the performance average is calculated.",
    'save simulation': 'save simulation',
    'Name': 'Name',
    'Description': 'Description',
    'The simulation has been saved': 'The simulation has been saved',
    'clear': 'clear',
    'rem': 'R.E.M.',
    'Effectiveness in points played with the first and second serve and with the return of the first and second serve.': 'Effectiveness in points played with the first and second serve and with the return of the first and second serve.',
    'Detail and effectiveness of rally lenght on serve.': 'Detail and effectiveness of rally lenght on serve.',
    'Detail and effectiveness of rally lenght on serve return.': 'Detail and effectiveness of rally lenght on serve return.',
    'Detail and effectiveness of rally lenght on serve and serve return.': 'Detail and effectiveness of rally lenght on serve and serve return.',
    'Detail of the percentage of won points with first and second serve. It also includes the percentage of won points with the first and second serve on the deuce and ad court.': 'Detail of the percentage of won points with first and second serve. It also includes the percentage of won points with the first and second serve on the deuce and ad court.',
    '1st Serve In': '1st Serve In',
    'Deuce and Ad Points on 1st Serve In': 'Deuce and Ad Points on 1st Serve In',
    'Points Won on 1st Serve In': 'Points Won on 1st Serve In',
    'Deuce and Ad Points Won on 1st Serve In': 'Deuce and Ad Points Won on 1st Serve In',
    '2nd Serve Points Played out of Total Service': '2nd Serve Points Played out of Total Service',
    'Deuce and Ad Points on 2nd Serve In': 'Deuce and Ad Points on 2nd Serve In',
    '2nd Serve Effectiveness': '2nd Serve Effectiveness',
    'Deuce and Ad Points Won on 2nd Serve': 'Deuce and Ad Points Won on 2nd Serve',
    'Detail of the percentage of won points with first and second serve return. It also includes the percentage of won points with the first and second serve return on the deuce and ad court.': 'Detail of the percentage of won points with first and second serve return. It also includes the percentage of won points with the first and second serve return on the deuce and ad court.',
    '1st Serve Return In': '1st Serve Return In',
    'Deuce and Ad Points on 1st Serve Return In': 'Deuce and Ad Points on 1st Serve Return In',
    '1st Serve Return Effectiveness': '1st Serve Return Effectiveness',
    'Deuce and Ad Points Won on 1st Serve Return': 'Deuce and Ad Points Won on 1st Serve Return',
    '2nd Serve Return In': '2nd Serve Return In',
    'Deuce and Ad Points on 2nd Serve Return In': 'Deuce and Ad Points on 2nd Serve Return In',
    '2nd Serve Return Effectiveness': '2nd Serve Return Effectiveness',
    'Deuce and Ad Points Won on 2nd Serve Return': 'Deuce and Ad Points Won on 2nd Serve Return',
    "The return effective margin refers to the effectiveness in game's return, including the performance of the first and second serve return. On this basis, the probability that the opponent serve will be broken is calculated.": "The return effective margin refers to the effectiveness in game's return, including the performance of the first and second serve return. On this basis, the probability that the opponent serve will be broken is calculated.",
    'complete the following steps to upload your tennis match': 'complete the following steps to upload your tennis match',
    'upload file': 'upload file',
    'match information': 'match information',
    'confirm': 'confirm',
    'no file selected': 'no file selected',
    'Only .csv file extension will be acepted': 'Only .csv file extension will be acepted',
    'back': 'back',
    'next': 'next',
    'No have awards information yet.': 'No have awards information yet.',
    'choose file': 'Choose file',
    'show performance': 'Historical Performance Chart',
    'show unforced errors': 'Historical Determinant Unforced Errors Chart',
    'show rally serve': 'Historical Serve Rally Length Chart',
    'show rally return': 'Historical Serve Return Rally Length Chart',
    'show rally serve return': 'Historical Serve and Return Rally Length Chart',
    'rally serve': 'Rally Serve',
    'rally return': 'Rally Return',
    'rally serve and return': 'Rally Serve and Return',
    'show serve1stIn': 'Historical 1st Serve In Chart',
    'show serve1stEffectiveness': 'Historical 1st Serve Effectiveness Chart',
    'show serve2ndPoints': 'Historical 2nd Serve Points Chart',
    'show serve2ndEffectiveness': 'Historical 2nd Serve Effectiveness Chart',
    'show sem': 'Historical S.E.M. Chart',
    'show return1stIn': 'Historical 1st Serve Return In Chart',
    'show return1stEffectiveness': 'Historical 1st Serve Return Effectiveness Chart',
    'show return2ndPoints': 'Historical 2nd Serve Return Points Chart',
    'show return2ndEffectiveness': 'Historical 2nd Serve Return Effectiveness Chart',
    'show rem': 'Historical R.E.M. Chart',
    'show minimatches': 'Historical Minimatches Chart',
    'show radarReport': 'Skillset Chart',
    'Summary skills': 'Summary skills',
    'other': 'Other',
    'set margin': 'Set margin',
    'Your performance average is: ': 'Your performance average is: ',
    'You have set your desired margin': 'You have set your desired margin',
    'You have to complete required fields': 'You have to complete required fields',
    'Start date': 'Start date',
    'End date': 'End date',
    'moderated': 'Moderated (+10%)',
    'realistic': 'Realistic (+20%)',
    'optimum': 'Optimum (+30%)',
    'Your desired margin has been deleted': 'Your desired margin has been deleted',
    'You have already set a desired margin': 'You have already set a desired margin',
    'Do you want to delete it and set up a new one?': 'Do you want to delete it and set up a new one?',
    'You have successfully changed your password': 'You have successfully changed your password',
    'Do you want to delete match': 'Do you want to delete match',
    'You have successfully deleted your match': 'You have successfully deleted your match',
    'initial': 'Initial',
    'intermediate': 'Intermediate',
    'final': 'Final',
    'in play': 'in play',
    'points won': 'points won',
    '1st Serve Return': '1st Serve Return',
    '2nd Serve Return': '2nd Serve Return',
    'General Serve Return Stats': 'General Serve Return Stats',
    'Add dateBirth...': 'Add dateBirth...',
    'Add height...': 'Add height...',
    'Add weight...': 'Add weight...',
    'summary': 'Summary',
    'Points': 'Points',
    'Go to court': 'Go to court',
    'Points won by player': 'Points won by player',
    'courtBase': 'Court base',
    'Go to upload match': 'Go to upload match',
    'No matches loaded': 'No matches loaded',
    'points won on the most often rally played': 'Points won on the most often rally played',
    'break points': 'break points',
    '1st serve': '1st Serve',
    '1st serve return': '1st Serve Return',
    'key shot hand': 'Key Shot Hand',
    'key shot type': 'Key Shot Type',
    '2nd serve': '2nd Serve',
    '2nd serve return': '2nd Serve Return',
    'key shot outcome': 'Key Shot Outcome',
    'hide filters': 'Hide filters',
    'show filters': 'Show filters',
    'view match': 'View Match',
    'total unforced errors in a row': 'Total unforced errors in a row',
    'help points won by player': 'A quick reference of how many points were won by each player',
    'help match quality ranking': 'It is a ranking of the quality of your last ten matches, determined by combining your match performance rating and that of your opponents.',
    'help leadership margin': 'Your last shot performance and the one of your rival, will generate this pie chart showing who has been the leader during the match no matter the result. You can still loose but being the one who attacked more.',
    'help flow p1': 'The flow shows how the match was played and where were held the difference that made one player beat the other.',
    'help flow li1': "Grey rectangle on a player's side: This means this player is serving",
    'help flow li2': 'Yellow ball: serve in',
    'help flow li3': 'Red ball: serve out',
    'help flow li4': 'Dark green dot: forced winner',
    'help flow li5': 'Light green dot: Ace/winner',
    'help flow li6': 'Red dot: Unforced error',
    'help flow li7': 'Grey horizontal bar graph: The rally of that point.',
    'help flow li8': 'Light blue graph line: When a player wins the point, no matter how, this line will move towards them.',
    'help flow p2': 'This graph leads to a lot of information that can be analyzed easily.',
    'help flow p3': 'For example: How many unforced errors in a row were done; How many points were won consecutively. How was the first serve of each player. Were the rallies long? What happened after a long rally? And many more.',
    'help points won on the most often rally played': 'The % of points won on the rally length that repeated the most during the last match.',
    'help match quality': 'The combination of your match performance raiting and your rivals one will generate a match quality value, that is shown in this colorfull logo, that can be, red, yellow or green according to the quality of the match.',
    'help match performance p1': 'Shows you what is you actual level of performance at every match you played. This level can be negative or positive. No matter what is the level shown, it will be better as It gets higher. There is no minimum nor maximum for this level. It will depend on different indicators obtained from your game such as: winners, unforced errors, serve effectiveness, return effectiveness, rally effectiveness, and many more.',
    'help match performance p2': 'You will have each match performance raiting, an average line, and an objective line that can be determined by you.',
    'help distribution of unforced errors in a row': 'This pie graph shows the distribution of consecutive unforced errors in different quantities.',
    'help total points in a row': 'Total points lost in a row',
    'help total chains': 'Number of “chains” you got on average in the last ten games',
    'rally effectiveness chart': 'Rally effectiveness chart',
    'help rally effectiveness chart': 'An interactive bar graph that shows you how many points you won, and you lost in every rally length shown. Also you can arrange those points or effectiveness to sort them as you like.',
    'forehand and backhand key shots': 'Forehand and Backhand Key Shots',
    'help forehand and backhand key shots': 'This pie is divided into the forehands and backhands that you have played in average during your last ten matches, or your last match (depending on the page you are working on). Also it will displayed how many of them you loose and you won. Furthermore, by clicking on the winners or unforced errors, you will get an extra information on the type of shot they were hitted.',
    'points won by player': 'Points won by player',
    'court': 'Court',
    'application': 'Application',
    'select language': 'Select language',
    'lost points in a row (LPR)': 'Lost Points in a Row (LPR)',
    'Distribution of lost points in a row': 'Distribution of lost points in a row',
    'undefined title': 'Undefined title',
    'help distribution of lost points in a row': 'This pie graph shows the distribution of consecutive lost points in different quantities.',
  },
};

export default locale_english